import React, {useState} from 'react';
import styled from 'styled-components';
import {Subtitle} from '../components/atoms';
import Contactfilter from '../components/molecules/ContactFilter';
import {Header, Intro, SEO} from '../components/organisms';
import {ContactPerson, ContactUs} from '../components/organisms/blocks';
import {PageContent} from '../components/organisms/Layout';
import useContactData from '../queries/useContactData';
import {NavigationContext} from '../services/navigation';
import {devices, colors} from '../styles/theme';

const FilterToggle = styled(Subtitle)`
  display: block;
  grid-column: 1 / -1;
  padding-left: 16px;
  font-size: 16px;
  color: ${colors.lightBlue};
  cursor: pointer;
  @media ${devices.tabletPortrait} {
    display: none;
  }
`;

const ContactPage = () => {

  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname);
  }, []);

  const {contactPage, contacts, regions, sectors} = useContactData();
  const {header, intro, contact} = contactPage || {};

  const [showFilters, setShowFilters] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState([]);

  const filteredContacts = contacts.filter(contact => (
    contact.regions.some(({id}) => selectedRegions.includes(id)) &&
    contact.sectors.some(({id}) => selectedSectors.includes(id))
  ));

  return (
    <PageContent>
      <SEO
        title={contactPage?.SEO?.title}
        description={contactPage?.SEO?.description}
        meta={[
          {
            name: 'keywords',
            content: contactPage?.SEO?.keywords,
          },
          {
            property: 'og:image',
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${header?.media?.file?.file?.publicURL}`
          }
        ]}
      />
      {header && <Header header={header}/>}
      {intro && <Intro intro={intro}/>}
      <FilterToggle onClick={() => setShowFilters(!showFilters)}>
        {showFilters ? 'Hide' : 'Show'}{' filters'}
      </FilterToggle>
      <Contactfilter
        regionOptions={regions}
        regions={selectedRegions}
        setRegions={setSelectedRegions}
        sectorOptions={sectors}
        sectors={selectedSectors}
        setSectors={setSelectedSectors}
        hidden={!showFilters}
      />
      {filteredContacts.map(person => (
        <ContactPerson key={person.id} contact={person}/>
      ))}
      {contact && <ContactUs {...contact}/>}
    </PageContent>
  );
};

export default ContactPage;
