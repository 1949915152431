import React from 'react';
import styled, {css} from 'styled-components';
import {colors, devices} from '../../styles/theme';
import {GridComponentContainer, Checkbox, Subtitle} from '../atoms';
import Img from 'gatsby-image';

const Container = styled(GridComponentContainer)`
  display: ${({hidden}) => hidden ? 'none' : 'grid'};
  background: ${colors.lightGrey};
  padding: 24px 20px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  @media ${devices.tabletPortrait} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${devices.desktop} {
    padding: 24px 0;
  }
`;

const Question = styled(Subtitle)`
  font-weight: bold;
  color: ${colors.blue};
  margin-bottom: 16px;
`;

const Column = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  padding-right: 32px;
  @media ${devices.desktop} {
    &:first-child {
      padding-left: 48px;
    }
  }
`;

const FilterCheckbox = styled(Checkbox)`
  margin-bottom: 24px;
`;

const iconImageStyle = css`
  width: 32px;
  height: 32px;
`;

const Icon = styled.div`
  margin: 0 16px 0 8px;
  display: flex;
  align-items: center;
  svg {
    ${iconImageStyle}
  }
`;

const IconImage = styled(Img)`${iconImageStyle}`;
const NativeIconImage = styled.img`
  ${iconImageStyle}
  margin: 0;
`;

const Contactfilter = ({
  regionOptions,
  regions,
  setRegions,
  sectorOptions,
  sectors,
  setSectors,
  hidden
}) => (
  <Container fromColumn={3} widthInColumns={4} hidden={hidden}>
    <Column>
      <Question>1. In what sector do you operate?</Question>
      {sectorOptions.map(({id, name, icon}) =>
        <FilterCheckbox
          key={id}
          checked={sectors.includes(id)}
          onChange={() => sectors.includes(id)
            ? setSectors(sectors.filter(x => x !== id))
            : setSectors([id, ...sectors])
          }
        >
          <Icon>
            {icon?.file?.extension === 'svg'
              ? <NativeIconImage
                src={icon?.file?.publicURL}
                alt={icon?.alternativeText}
              />
              : <IconImage
                fluid={icon?.file?.childImageSharp?.fluid}
                alt={icon?.alternativeText}
              />
            }
          </Icon>
          {name}
        </FilterCheckbox>
      )}
    </Column>
    <Column>
      <Question>2. In what region do you operate?</Question>
      {regionOptions.map(({id, name, icon}) => (
        <FilterCheckbox
          key={id}
          checked={regions.includes(id)}
          onChange={() => regions.includes(id)
            ? setRegions(regions.filter(x => x !== id))
            : setRegions([id, ...regions])
          }
        >
          <Icon>
            {icon?.file?.extension === 'svg'
              ? <NativeIconImage
                src={icon?.file?.publicURL}
                alt={icon?.alternativeText}
              />
              : <IconImage
                fluid={icon?.file?.childImageSharp?.fluid}
                alt={icon?.alternativeText}
              />
            }
          </Icon>
          {name}
        </FilterCheckbox>
      ))}
    </Column>
  </Container>
);

export default Contactfilter;
