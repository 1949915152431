import {graphql, useStaticQuery} from 'gatsby';

const useContactData = () => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        contactPage {
          SEO {
            ...SEO
          }
          header {
            ...RegularHeader
          }
          intro {
            ...Intro
          }
          contact {
            ...ContactUs
          }
        }
        contacts {
          ...ContactPerson
          id
          regions {
            id
          }
          sectors {
            id
          }
        }
        sectors {
          name
          id
          icon {
            ...MediaFile
          }
        }
        regions {
          name
          id
          icon {
            ...MediaFile
          }
        }
      }
    }
  `);

  return data.strapi;
};

export default useContactData;
